<template>
  <div class="main container product-page">
    <Breadcrumb :list="breadcrumbList" />
    <div class="content">
      <div class="left">
        <h2 class="title">特性</h2>
        <section
          v-for="item in charaterList"
          :key="item.id"
          class="animate__animated animate__fadeIn"
        >
          <h3 class="sub-title">{{ item.title }}</h3>
          <div v-if="item.sub && item.sub.length">
            <div v-for="row in item.sub" :key="row.id">
              <h4 class="sub-title1">{{ row.subTitle }}</h4>
              <p>{{ row.content }}</p>
            </div>
          </div>
          <p v-else>{{ item.content }}</p>
        </section>
      </div>
      <ProdMenu class="right hidden-sm-and-down" activeIndex="2" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ProdMenu from "@/components/ProdMenu";
import "animate.css";
export default {
  name: "Character",
  components: {
    Breadcrumb,
    ProdMenu,
  },
  data() {
    return {
      breadcrumbList: [
        { id: 1, path: "/", label: "ShuangzhaoDB" },
        { id: 2, path: "/product", label: "产品" },
        { id: 3, path: "", label: "特性" },
      ],
      charaterList: [
        {
          id: 1,
          title: "内存计算",
          content:
            "ShuangzhaoDB 是完全的内存数据库，所有的计算都在内存中完成，数据都存放在内存中，保证了计算和数据在最接近的地方。完全内存的计算保证了最大化利用现有硬件的能力，实现毫秒内的快速响应能力，数据完全内存化存储避免了导致缓慢的磁盘I/O开销。",
        },
        {
          id: 2,
          title: "高可用性",
          content:
            "ShuangzhaoDB 可以自定义数据的副本数量（0~集群节点数-1），用户只需在表定义时设定分区字段，数据就可自动分区，副本分区保存在不同的节点以保证有节点退出时不影响集群整体稳定性。ShuangzhaoDB支持多个集群组成多活或主备集群，如：两地三中心、同城双活等等。多活集群在备份的同时，每个集群还同时提供读写操作，保证安全的同时，提高了吞吐量。",
        },
        {
          id: 3,
          title: "线性扩容",
          content:
            "ShuangzhaoDB 性能会随着集群节点数量的增加而线性增长，随着应用端性能需求的增长，集群可以简单地增加节点数量来解决。",
        },
        {
          id: 4,
          title: "安全易用",
          content: "",
          sub: [
            {
              id: 1,
              subTitle: "标准SQL",
              content:
                "ShuangzhaoDB使用标准SQL，包括CREATE INDEX、CREATE TABLE、CREATE VIEW，SELECT、INSERT、UPDATE、DELETE操作等。学习成本和代码迁移成本很低，代码复用程度高，已经使用SQL实现的应用，可以相对容易的迁移到ShuangzhaoDB。",
            },
            {
              id: 2,
              subTitle: "ACID",
              content:
                "传统的数据库为了保证事务完整性，往往耗费非常多的CPU资源在锁、插销和缓冲区管理这些执行准备工作上，阻碍了数据库的性能和扩展能力。ShuangzhaoDB通过全局事务串行执行和单线程分区并行执行,保证事务执行串行隔离，同时避免了锁的使用和缓冲区的管理，同时满足ACID和高性能。",
            },
            {
              id: 3,
              subTitle: "复杂计算",
              content:
                "ShuangzhaoDB使用行式结构存储数据，兼容JAVA数据类型，同时提供了多表JOIN、聚合、窗口、地理等查询。同时，ShuangzhaoDB提供数据即席查询(Ad-Hoc)，可根据自己的需求灵活的选择查询条件，以作为实时数据分析和实时数仓使用。",
            },
            {
              id: 4,
              subTitle: "JDBC",
              content:
                "ShuangzhaoDB兼容JDBC，允许用户进行动态的SQL查询，动态SQL查询也能参与存储结构优化（比如索引），所以可根据应用需求结合预先编好存储过程一起使用。",
            },
          ],
        },
        {
          id: 5,
          title: "运维监控",
          content:
            "ShuangzhaoDB提供丰富的运维监控工具，能够实时监控集群运行状态，查询执行状态等，并可按需配置监控报警事件。",
        },
        {
          id: 6,
          title: "云化部署",
          content:
            "ShuangzhaoDB支持虚拟化、容器化部署，可无缝运行于虚拟化环境。支持特性：集中控制、部署、在线扩容、在线缩容、自动化编排k8s。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/product.less";
</style>